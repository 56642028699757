@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Roboto font */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

body {
  font-family: "Roboto", sans-serif;
}

.fade-in {
  opacity: 1;
  transition: all 2s;
}

.grecaptcha-badge {
  visibility: hidden;
}

.loader {
  border: 4px solid #fddb3a; /* Yellow color */
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 400px) {
  #aboutImage {
    height: 10rem;
  }

  #aboutContainer {
    height: 20rem;
  }
}

@media (min-width: 600px) and (max-width: 1022px) {
  #phone {
    display: none;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-left {
  animation: slideInFromLeft 1.8s forwards;
}

.animate-right {
  animation: slideInFromRight 1.8s forwards;
}
